import React, { useRef, useState, useEffect, Fragment } from 'react';
import { useQueryParam } from 'gatsby-query-params';
import axios from 'axios';

function RightToBeForgottenVerify(lang) {
  const uid = useQueryParam('uid', 'None'); // key, defaultValue
  const [verifyCloud, setVerifyCloud] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // https://stackoverflow.com/questions/53179075/with-useeffect-how-can-i-skip-applying-an-effect-upon-the-initial-render
  // Does not run on mount
  const didMountRef = useRef(false);
  useEffect(() => {
    const fetchData = async () => {
      if (didMountRef.current) {
        setIsLoading(true);
        try {
          const result = await axios.post(
            `${process.env.BACKEND_API_URL}/@verify-delete-cloud`,
            {
              uid,
              lang,
            },
            {
              headers: {
                accept: 'application/json',
              },
            },
          );
          setVerifyCloud(true);
        } catch (error) {
          setVerifyCloud(false);
        }
        setIsLoading(false);
      } else {
        didMountRef.current = true;
      }
    };
    fetchData();
  }, [lang, uid]);
  return (
    <Fragment>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <ul>
          {verifyCloud ? 'Cloud verified' : 'Cloud not verified'}
        </ul>
      )}
    </Fragment>
  );
}

export default RightToBeForgottenVerify;
