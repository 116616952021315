import React from 'react';
import Layout from '../../components/common/Layout';
import RightToBeForgottenVerify from '../../components/from/RightToBeForgotten/RightToBeForgottenVerify';
import SEO from '../../components/SEO';

const RightToBeForgottenVerifyPage = () => (
  <Layout lang="de">
    <SEO
      title="Recht auf Vergessenwerden Prüfung - Nextcloud Hosting"
      description="Bitte prüfe dein Postfach."
    />
    <RightToBeForgottenVerify lang="de" />
  </Layout>
);

export default RightToBeForgottenVerifyPage;
